import store from "@/store";
import { $api } from "@/services/api";

export default function useChecklistHelpers() {
  function title(item: string) {
    let title = item;
    switch (item) {
      case "ADDMismatch":
      case "Address Mismatch":
        title = "Address Verification"
        break;
      case "ExecuteLegalDocPackage":
      case "Execute Legal Doc Package":
        title = "Final Documents"
        break;
      case "SSNMistmatchProofSSN":
      case "Social Security Mismatch":
        title = "SSN Verification"
        break;
      default:
        return title;
    }
    return title;
  }
  function groupItems(array: any[], property: any) {
    const reducer = function (groups: any, item: any) {
      const name = item[property];
      const group = groups[name] || (groups[name] = []);
      group.push(item);
      return groups;
    };
    return array.reduce(reducer, {});
  }

  function processingCategoryTitle(stip: any) {
    let title = "";
    // Custom grouping
    if (stip.processingCategoryName === 'Membership') {
      title = "Credit Union Membership"
    }
    else if (stip.processingCategoryName === 'Identity') {
      if (stip.stipulationTypeName === 'SocialSecurityCard' || stip.stipulationTypeName === 'TaxFormNameSSN') {
        title = "SSN Verification";
      } else {
        title = `${stip.processingCategoryName} Verification`;
      }
    }
    else if (stip.processingCategoryName === 'Credit') {
      if (stip.stipulationTypeName === 'SSNMismatch' || stip.stipulationTypeName === 'SSNMistmatchProofSSN') {
        title = "SSN Verification";
      } else if (stip.stipulationTypeName === 'ADDMismatch') {
        title = "Address Verification";
      } else {
        title = "Credit Bureau Action"
      }
    }
    else if (stip.processingCategoryName === 'ESign') {
      title = "Final Documentation";
    }
    else if (stip.processingCategoryName === 'Misc') {
      title = "Other"
    }
    else if (stip.processingCategoryName === 'LoanConfiguration') {
      title = "Rate and Repayment";
    }
    else if (stip.processingCategoryName === 'SpousalConsent') {
      title = stip.processingCategoryName?.replace(/([A-Z])/g, ' $1')?.trim() || stip.processingCategoryName;
    }
    else if (stip.processingCategoryName === 'FinalReview') {
      title = stip.processingCategoryName?.replace(/([A-Z])/g, ' $1')?.trim() || stip.processingCategoryName;
    }
		else if (stip.processingCategoryName === 'PayoffVerification') {
			title = stip.processingCategoryName?.replace(/([A-Z])/g, ' $1')?.trim() || stip.processingCategoryName;
		}
		else if (stip.processingCategoryName === 'EligibilityVerification') {
			title = stip.processingCategoryName?.replace(/([A-Z])/g, ' $1')?.trim() || stip.processingCategoryName;
		}
    else {
      title = `${stip.processingCategoryName} Verification`;
    }
    return title;
  }

  function stipulationStatusReasonId(statusName: string) {

    let statusReason = "Open";
    switch (statusName) {
      case "Open":
        statusReason = "Open";
        break;
      case "Completed":
        statusReason = "Fulfilled"
        break;
      case "CU-Override":
        statusReason = "Override"
        break;
    }
    const statusReasonUpdate = store?.state?.lookups?.stipStatusReasons?.find((s: any) => s.name.trim() === statusReason);
    return statusReasonUpdate.id;
  }

  async function updateStipulationStatus(applicationId: number, status: any, stip: any) {
    const updateStipRequest = {
      stipulationStatusId: status.id,
      stipulationStatusReasonId: stip.stipulationStatusReasonId,
      stipulationTextOverride: null,
      revertToDefaultText: false,
      updatedByUserId: store.state.oidcStore.user.sub
    };

    let response = {} as any;
    // applicant level
    if (stip.applicantId && stip.applicantId > 0) {
      response = await $api.applications.updateApplicantStipulation(applicationId, stip.applicantId, stip.id, updateStipRequest);
      stip.stipulationStatusId = response.stipulationStatusId;
      stip.stipulationStatusName = response.stipulationStatusName;
    } else {
      response = await $api.applications.updateApplicationStipulation(applicationId, stip.id, updateStipRequest);
      stip.stipulationStatusId = response.stipulationStatusId;
      stip.stipulationStatusName = response.stipulationStatusName;
    }

    return stip;
  }
  
  function abstractTfn(string: string) {
    const regex = new RegExp("\\+?\\(?\\d*\\)? ?\\(?\\d+\\)?\\d*([\\s./-]?\\d{2,})+", "g");

    const result = string.match(regex) || "";
    if ((result || []).length > 0) {
      return result[0].trim();
    } else {
      return string;
    }
  }

  function replaceObjectWithId(arr: Array<any>, id: number, newObj: object) {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
    
    if (objWithIdIndex > -1) {
      arr[objWithIdIndex] = Object.assign({}, newObj);
    }
  
    return arr;
  }

  return {
    groupItems,
    title,
    processingCategoryTitle,
    stipulationStatusReasonId,
    updateStipulationStatus,
    abstractTfn,
    replaceObjectWithId
  }
}
