
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { mapActions, mapGetters, mapState } from "vuex";
import Loader from "@/components/shared/Loader.vue";
import Hero from "@/components/shared/layout/Hero.vue";
import Header from "@/components/shared/layout/Header.vue";
import Footer from "@/components/shared/layout/Footer.vue";
import Feedback from "@/components/shared/feedback/Feedback.vue";
import SupportModal from "@/components/modals/SupportModal.vue";
import useFormatter from "@/use/formatter";
import useApplications from "@/use/application";
export default defineComponent({
  setup() {
    const route = useRoute();
    const { formatHexToRgb } = useFormatter();
    const { getLenderConfigurationByReferenceId } = useApplications();
    const showSupportModal = ref<boolean>(false);

    return { formatHexToRgb, route, getLenderConfigurationByReferenceId, showSupportModal };
  },
  props: {
    externalLinks: {
      type: Boolean,
      default: false,
    },
    pullConfiguration: {
      type: Boolean,
      default: true,
    },
  },
  components: { Loader, Hero, Header, Footer, Feedback, SupportModal },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapState(["applications", "idleVue", "lenderData", "featureFlags", "configuration"]),
    ...mapGetters("oidcStore", ["oidcUser", "oidcIsAuthenticated"]),
    themeStyle(): any {
      const themeRgb = this.formatHexToRgb(this.lenderData?.primaryColor);
      const secondaryRgb = this.formatHexToRgb(this.lenderData?.secondaryColor);
      return {
        "--theme-color": this.lenderData?.primaryColor,
        "--theme-color-rgb": `${themeRgb.r}, ${themeRgb.g}, ${themeRgb.b}`,
        "--secondary-color": this.lenderData?.secondaryColor,
        "--secondary-color-rgb": `${secondaryRgb.r}, ${secondaryRgb.g}, ${secondaryRgb.b}`,
      };
    },
    lid(): number {
      return (this.lenderData as any).lid || this.$route.query?.lid || 0;
    },
  },
  methods: {
    ...mapActions([
      "setApplications",
      "setLenderData",
      "setDefaultLender",
      "setFeatureFlags",
      "setConfiguration",
    ]),
  },
  async mounted() {
    const query = this.route.query as any;

    // pass LID query param
    let lid = query.lid || query.LID || 0;

    if (lid === 0) {
      lid = localStorage.getItem("slc-lid") || 0;
    }

    if (this.oidcUser !== null && this.lenderData === undefined) {
      this.getLenderConfigurationByReferenceId(
        this.oidcUser.sub,
        this.$route.params?.referenceId as string,
      )
        .then(async (result: any) => {
          if (result.lenderData) {
            await this.setLenderData(result.lenderData);
            localStorage.setItem("slc-lid", lid);
          } else {
            await this.setDefaultLender();
          }
          if (result.borrowerApplicationUrl && result.docUploadUrl) {
            const newConfig = {
              ...this.configuration,
              borrowerApplicationUrl: result.borrowerApplicationUrl,
              docUploadUrl: `${result.docUploadUrl}/api/v1/DocUploadService`,
              identityUrl: result?.identityUrl,
              payOffSample: result?.payOffSample,
              loanAccountSample: result?.loanAccountSample,
            };
            this.setConfiguration(newConfig);
          }

          // In the case a page's content is dependent on configuration data
          this.loading = false;

          this.$emit("configured", true);
        })
        .catch((err: any) => {
          console.log("ERROR GETTING CONFIG", err);
          // set default lender settings
          this.setDefaultLender();
          this.loading = false;
        });
    } else if (this.lenderData === undefined) {
      // When a user hits a non-authenticated page we need default settings
      this.setDefaultLender();
      this.loading = false;
    } else {
      // Fallback to clear loader when user is already in app and navigating pages
      this.loading = false;
    }
  },
});
